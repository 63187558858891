<script setup>
import { RouterView } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import helpers from '@/helpers';
import { onMounted, watch } from 'vue';

const auth = useAuthStore();

let cpf = '';

onMounted(async () => {
  loadHeaderFooter();
});

const loadHeaderFooter = () => {
  const suporte = {
    telefone: '(11) 5555-9252',
    email: 'suporte.crc@registrocivil.org.br',
  };

  let script = document.createElement('script');
  script.setAttribute('type', 'module');
  script.innerHTML = `
        import {instalarHeaderFooter} from '${
          import.meta.env.VITE_URL_BASE_HEADER_FOOTER
        }/header-footer.min.js';
          instalarHeaderFooter({
              usuario: {
                  nome: '${auth.user?.nome ? auth.user?.nome : ''}',
                  cpf: '${cpf}',
                  foto: '${auth.user?.foto_de_perfil_url || ''}',
                  grupos: ${JSON.stringify(auth.user?.grupos)}
              },
              urlLogin: '${auth.urlLogin()}',
              urlLogout: '${auth.urlLogout()}',
              urlRedirecionamentoSPRC: '${auth.urlLogin()}',
              token: '${auth.getToken()}',
              exibirPerguntasFrequentes: ${true},
              exibirFaleConoscoPeloChat: ${true},
              suporte: '${JSON.stringify(suporte)}',
              exibirAcessoRemoto: ${true},
              exibirWhatsApp: ${true},
          });
      `;
  document.body.appendChild(script);
};

watch(
  () => auth.user,
  (newValue) => {
    if (newValue) {
      cpf = auth.user?.cpfRegistrador
        ? auth.user?.cpfRegistrador
        : auth.user?.cpf
        ? helpers.formatarCPF(auth.user.cpf)
        : '';
      loadHeaderFooter();
    }
  }
);
</script>

<template>
  <div id="appContainer">
    <div id="header"></div>
    <RouterView />
    <div id="footer"></div>
  </div>
</template>
<style scoped>
#main {
  min-height: 84vh;
}
</style>

<style scoped lang="scss">
@import url($env_url_base_header_footer + '/header-footer.css');
#content {
  background-color: white;
  margin-left: 75px;
}
</style>
